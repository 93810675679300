import { Box, IconButton, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import { ColorModeContext } from "../../theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { Typography, useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";

const Topbar = ({ setIsHideNavBar }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [hideNavBar, setHideNavBar] = useState(isMobileOrTablet);

  const { isLoggedIn, user, logout, hasPermission } = useContext(AuthContext);

  const onClickUserIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const handleLogout = () => {
    logout(); // Clear tokens and user data
    toast.success("Logout successful!");
    navigate("/login");
    handleMenuClose();
  };

  const handleLogin = () => {
    navigate("/login");
    handleMenuClose();
  };

  const onClickSettingIcon = () => {
    navigate("/settings");
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const handleToggleNavBar = () => {
    setHideNavBar(!hideNavBar);
    setIsHideNavBar(!hideNavBar);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="flex-start" p={2}>
        {isMobileOrTablet && (
          <Tooltip title="Navigation">
            <IconButton onClick={handleToggleNavBar}>
              <MenuOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Go Back">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {/* ICONS */}
        <Box display="flex">
          <Tooltip title="Change Theme">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
          {isLoggedIn ? (
            hasPermission("settings") ? (
              <Tooltip title="Settings">
                <IconButton onClick={onClickSettingIcon}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="No Access">
                <IconButton disabled>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            )
          ) : null}

          <Tooltip title="User">
            <IconButton onClick={onClickUserIcon}>
              <PersonOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {isLoggedIn ? (
              <Box>
                <MenuItem disabled>
                  <PersonOutlinedIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {user?.name || "User"}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleProfileClick}>
                  <Avatar /> Profile
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    mt: "10px",
                  }}
                >
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
              </Box>
            ) : (
              <Box>
                <MenuItem
                  onClick={handleLogin}
                  sx={{
                    mt: "10px",
                  }}
                >
                  <LoginIcon sx={{ mr: 1 }} /> Login
                </MenuItem>
              </Box>
            )}
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
