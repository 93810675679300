import { tokens } from "../../theme";
import ApiService from "../../services/ApiService.js";
import Header from "../../components/Header.jsx";
import TableComponent from "../../components/TableComponent.jsx";
import ButtonComponent from "../../components/ButtonComponent.jsx";

import {
  Box,
  useTheme,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MachineStatus = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const colors = tokens(theme.palette.mode);
  const apiService = new ApiService(
    process.env.REACT_APP_MACHINE_DATA_BACKEND_API_PORT
  );
  const showMachineDetail =
    process.env.REACT_APP_SHOW_MACHINE_DETAIL === "true";

  const [iotData, setIotData] = useState([]);
  const [tableSelectedData, setTableSelectedData] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(""); // Store last update time
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "TowerLightStatus",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.value;
        let color;

        // Define colors based on status
        switch (status) {
          case 2:
            color = colors.redAccent[500];
            break;
          case 1:
            color = colors.greenAccent[400];
            break;
          case 0:
            color = "#FCF55F";
            break;
          default:
            color = colors.primary[200];
            break;
        }

        return (
          <span
            style={{
              display: "inline-block",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: color,
              margin: 0,
            }}
          ></span>
        );
      },
    },
    {
      field: "MachineRunRate",
      headerName: "Run",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "MachineIdleRate",
      headerName: "Idle",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "MachineErrorRate",
      headerName: "Error",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "lastError",
      headerName: "Last Error",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "TemperatureA",
      headerName: "Core Temperature",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const toleranceMin = params.row.TemperatureAMin;
        const toleranceMax = params.row.TemperatureAMax;

        return (
          <Typography
            sx={{
              fontWeight:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? "bold"
                  : "normal",
              color:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? colors.redAccent[500]
                  : "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "TemperatureB",
      headerName: "Cavity Temperature",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const toleranceMin = params.row.TemperatureBMin;
        const toleranceMax = params.row.TemperatureBMax;

        return (
          <Typography
            sx={{
              fontWeight:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? "bold"
                  : "normal",
              color:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? colors.redAccent[500]
                  : "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
  ];

  const columnsMobileStatus = [
    {
      field: "Name",
      headerName: "Machine",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const name = params.value;
        const number = name.replace(/\D+/g, ""); // Remove all non-numeric characters
        return <span>{number}</span>;
      },
    },
    {
      field: "TowerLightStatus",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.value;
        let color;

        // Define colors based on status
        switch (status) {
          case 2:
            color = colors.redAccent[500];
            break;
          case 1:
            color = colors.greenAccent[400];
            break;
          case 0:
            color = "#FCF55F";
            break;
          default:
            color = colors.primary[200];
            break;
        }

        return (
          <span
            style={{
              display: "inline-block",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: color,
              margin: 0,
            }}
          ></span>
        );
      },
    },
    {
      field: "MachineRunRate",
      headerName: "Run",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "MachineIdleRate",
      headerName: "Idle",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "MachineErrorRate",
      headerName: "Error",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const columnsTemperature = [
    {
      field: "Name",
      headerName: "Machine",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const name = params.value;
        const number = name.replace(/\D+/g, ""); // Remove all non-numeric characters
        return <span>{number}</span>;
      },
    },
    {
      field: "lastError",
      headerName: "Last Error",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "TemperatureA",
      headerName: "CoreTemp",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const toleranceMin = params.row.TemperatureAMin;
        const toleranceMax = params.row.TemperatureAMax;

        return (
          <Typography
            sx={{
              fontWeight:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? "bold"
                  : "normal",
              color:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? colors.redAccent[500]
                  : "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "TemperatureB",
      headerName: "CavityTemp",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        const toleranceMin = params.row.TemperatureBMin;
        const toleranceMax = params.row.TemperatureBMax;

        return (
          <Typography
            sx={{
              fontWeight:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? "bold"
                  : "normal",
              color:
                params.value >= toleranceMax || params.value < toleranceMin
                  ? colors.redAccent[500]
                  : "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
  ];

  ////////////////////FUNCTION///////////////////////
  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData(); // Refresh data every 5 minutes
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    setIsRefreshing(true);
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      var response = await apiService.get(`${apiVersion}\\iot-data?sort=_id`);

      if (
        response &&
        response.data.length > 0 &&
        response.data.some((item) => item !== null)
      ) {
        const transformedData = response.data.map((data, index) => {
          return {
            ...data,
            lastError: getLastError(data.LastModifiedTime),
            id: index + 1,
          };
        });

        setIotData(transformedData);
        setLastUpdate(
          new Date().toLocaleString("en-US", {
            dateStyle: "short",
            timeStyle: "short",
          })
        ); // Update the last update time
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getLastError = (lastModifyTime) => {
    const givenDate = new Date(lastModifyTime);
    const now = new Date();

    const timeDifferent = new Date(now.getTime() - givenDate); // 60 minutes * 60 seconds * 1000 milliseconds

    if (timeDifferent > 12 * 60 * 60 * 1000) {
      return "> 12 hours ago";
    } else {
      return givenDate.toLocaleString("en-US", {
        dateStyle: "short",
        timeStyle: "short",
      });
    }
  };

  const tableSelectionChange = (data) => {
    setTableSelectedData(data[0]);
  };

  const handleDetailClick = () => {
    if (tableSelectedData)
      navigate(`/machineDetail/${tableSelectedData.MachineId}`);
  };

  const handleRefreshClick = () => {
    fetchData();
  };

  return (
    <Box m="20px">
      <Header title="Machine Status" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <ButtonComponent
          label="Refresh"
          onClick={handleRefreshClick}
          disabled={isRefreshing} // Disable when loading
          icon={isRefreshing ? <CircularProgress size={20} /> : null}
        />
        <Typography
          variant="body2"
          color={colors.grey[400]}
          sx={{ ml: "10px" }}
        >
          Last updated: {lastUpdate}
        </Typography>
      </Box>
      {showMachineDetail && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "10px",
          }}
        >
          <Typography variant="h5" color={colors.greenAccent[400]}>
            Select a machine and click the Detail button to view the machine
            details.
          </Typography>
          <ButtonComponent
            label="Details"
            onClick={handleDetailClick}
            disabled={tableSelectedData === null}
          />
        </Box>
      )}
      <Box>
        <TableComponent
          data={iotData}
          columns={isMobileOrTablet ? columnsMobileStatus : columns}
          customStyles={{
            height: isMobileOrTablet ? "400px" : "600px",
            overflowX: "auto",
          }}
          onRowSelect={tableSelectionChange}
          showToolbar={isMobileOrTablet ? false : true}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: isMobileOrTablet ? 5 : 10,
              },
            },
          }}
          disableColumnMenu={isMobileOrTablet ? true : false}
          disableColumnSorting={isMobileOrTablet ? true : false}
        />
        {isMobileOrTablet && (
          <TableComponent
            data={iotData}
            columns={columnsTemperature}
            customStyles={{
              height: isMobileOrTablet ? "400px" : "600px",
              mt: "20px",
              mb: "20px",
              overflowX: "auto",
            }}
            onRowSelect={tableSelectionChange}
            showToolbar={isMobileOrTablet ? false : true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: isMobileOrTablet ? 5 : 10,
                },
              },
            }}
            disableColumnMenu={isMobileOrTablet ? true : false}
            disableColumnSorting={isMobileOrTablet ? true : false}
          />
        )}
      </Box>
    </Box>
  );
};

export default MachineStatus;
