import ButtonComponent from "../../components/ButtonComponent";
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { tokens } from "../../theme";
import ApiService from "../../services/ApiService";
import TreeComponent from "../../components/TreeComponent"; // Import reusable TreeComponent
import userRights from "../../config/accessibilities"; // Import the hierarchical rights
import AddIcon from "@mui/icons-material/Add";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { AuthContext } from "../../context/AuthContext";

import { toast } from "react-toastify";

const Accessibility = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user } = useContext(AuthContext);
  const [groups, setGroups] = useState([]); // List of user groups
  const [selectedGroup, setSelectedGroup] = useState(""); // Selected group
  const [accessRightsState, setAccessRightsState] = useState([]); // Rights for the selected group
  const [loading, setLoading] = useState(false); // Loading indicator
  const [accessibilityID, setAccessibilityID] = useState("");
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmationModal] =
    useState(false);

  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  const userGroupId = user?.group_id.id;

  // Fetch groups on component mount
  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
        const response = await apiService.get(`${apiVersion}/user-group`);
        setGroups(response.data || []);
      } catch (error) {
        console.error("Failed to fetch groups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  // Fetch access rights for the selected group
  const handleGroupChange = async (event) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    setAccessRightsState([]); // Reset rights while fetching new data
    setAccessibilityID("");

    if (groupId) {
      setLoading(true);
      try {
        const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
        const response = await apiService.get(`${apiVersion}/accessibility`, {
          group_id: groupId,
        });

        if (response && response.data && response.data[0]) {
          const permissions = response.data[0].permission || [];
          setAccessRightsState(permissions);
          setAccessibilityID(response.data[0]._id);
        }
      } catch (error) {
        console.error("Failed to fetch group access rights:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Save access rights
  const handleSave = async () => {
    console.log(selectedGroup);
    console.log(userGroupId);
    if (selectedGroup === userGroupId) {
      toast.error("You cannot modify access rights for your own group.");
      return;
    }

    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      var res;
      if (accessibilityID !== "") {
        res = await apiService.patch(
          `${apiVersion}/accessibility/${accessibilityID}`,
          {
            permission: accessRightsState,
          }
        );
      } else {
        res = await apiService.post(`${apiVersion}/accessibility`, {
          permission: accessRightsState,
          group_id: selectedGroup,
        });
      }
      if (res.status === "success") {
        toast.success(`Access right updated.`);
      } else {
        toast.error(`Failed to update access rights!`);
      }
    } catch (error) {
      console.error("Failed to update access rights:", error);
      toast.error(`Failed to update access rights!`);
    }
  };

  const prepareTreeViewData = (nodes) => {
    return nodes.map((node) => ({
      id: node.key, // Unique identifier for the node
      label: node.label, // Label for the node
      children: node.children ? prepareTreeViewData(node.children) : undefined, // Recursively process children
    }));
  };

  const updateSelectedItem = (selectedList) => {
    setAccessRightsState(selectedList);
  };

  // Create a new group
  const handleCreateGroup = async () => {
    try {
      const res = await apiService.post(
        `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/user-group`,
        { name: newGroupName }
      );

      const createdGroup = res;
      setSelectedGroup(createdGroup.id);
      toast.success(`New user group ${newGroupName} created.`);
      setGroups((prevGroups) => [...prevGroups, res]);
      setShowGroupModal(false);
      setNewGroupName("");
    } catch (error) {
      if (error.status === 400) {
        toast.error(`The user group already exist!`);
      } else toast.error(`Error in creating user group!`);
    }
  };

  const onClickDeleteUserGroup = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleDeleteGroup = async () => {
    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const res = await apiService.delete(
        `${apiVersion}/user-group/${selectedGroup}`
      );
      if (res) {
        toast.success(`Group "${getGroupName()}" deleted successfully.`);
      } else {
        toast.error("Failed to delete the group.");
      }
      setGroups((prevGroups) =>
        prevGroups.filter((group) => group.id !== selectedGroup)
      );
      setSelectedGroup(""); // Clear selected group
      setShowDeleteConfirmationModal(false);
    } catch (error) {
      console.error("Failed to delete group:", error);
      toast.error("Failed to delete the group.");
    }
  };

  const getGroupName = () => {
    const group = groups.find((group) => String(group.id) === selectedGroup);
    return group ? group.name : "Unknown";
  };

  return (
    <Box>
      <Typography variant="h5" mb={3} color={colors.greenAccent[400]}>
        User Rights Management
      </Typography>

      {/* Group Selection */}
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="group-select-label">Select a User Group</InputLabel>
        <Select
          labelId="group-select-label"
          value={selectedGroup}
          onChange={handleGroupChange}
          disabled={loading}
          sx={{
            border: `1px solid ${colors.grey[600]}`,
            borderRadius: "4px",
          }}
        >
          <MenuItem value="" disabled>
            -- Select Group --
          </MenuItem>
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id} name={group.name}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonComponent
        label="Add User Group"
        onClick={() => setShowGroupModal(true)}
        startIcon={<AddIcon />}
        customStyles={{
          mb: "20px",
        }}
      />
      <ButtonComponent
        label="Delete User Group"
        onClick={onClickDeleteUserGroup}
        startIcon={<ClearOutlinedIcon />}
        customStyles={{
          mb: "20px",
          ml: "20px",
        }}
        type="warning"
        disabled={selectedGroup === "" || loading}
      />

      {/* TreeComponent for Access Rights */}
      <Typography variant="h6" mb={2}>
        Access Rights
      </Typography>
      <Box
        sx={{
          height: "40vh",
          maxHeight: "40vh", // Set a fixed height
          overflowY: "auto", // Enable vertical scrolling
          border: `1px solid ${colors.grey[600]}`, // Optional border for better visual
          borderRadius: "4px",
          padding: "8px",
        }}
      >
        <TreeComponent
          data={prepareTreeViewData(userRights)}
          checkbox={true}
          getSelectedList={updateSelectedItem}
          selectedKeys={accessRightsState}
        />
      </Box>

      <ButtonComponent
        label="Save Changes"
        onClick={handleSave}
        disabled={!selectedGroup || loading}
        customStyles={{
          mt: "20px",
        }}
      />

      <Dialog open={showGroupModal} onClose={() => setShowGroupModal(false)}>
        <DialogTitle>Add User Group</DialogTitle>
        <DialogContent>
          <TextField
            label="Group Name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            label="Cancel"
            onClick={() => setShowGroupModal(false)}
            customStyles={{
              m: "10px",
            }}
          />
          <ButtonComponent
            label="Create"
            onClick={handleCreateGroup}
            customStyles={{
              m: "10px",
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmationModal(false)}
      >
        <DialogTitle>Delete User Group</DialogTitle>
        <DialogContent>
          <Typography variant="h5">
            Confirm deleting the group <strong>{getGroupName()}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            label="NO"
            onClick={() => setShowDeleteConfirmationModal(false)}
            customStyles={{
              m: "10px",
            }}
          />
          <ButtonComponent
            label="YES"
            onClick={handleDeleteGroup}
            customStyles={{
              m: "10px",
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Accessibility;
