import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import UpTime from "./scenes/uptime";
import Vision from "./scenes/vision";
import ImageViewer from "./scenes/imageviewer";
import DailyOutput from "./scenes/dailyoutput";
import HourlyOutput from "./scenes/hourlyoutput";
import FiveMinutesOutput from "./scenes/fiveminutesoutput";
import MachineDetail from "./scenes/machinedetail";
import MachineStatus from "./scenes/machinestatus";
import TicketSystem from "./scenes/ticketsystem";
import Login from "./scenes/login";
import Signup from "./scenes/signup";
import ResetPassword from "./scenes/resetpassword";
import UserProfile from "./scenes/profile";
import Settings from "./scenes/settings";
import Accessibility from "./scenes/settings/Accessibility";
import UserAccount from "./scenes/settings/UserAccount";
import { ColorModeContext, useMode } from "./theme";

import { AuthProvider } from "./context/AuthContext";
import { NavigationProvider } from "./context/NavigateContext";

import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [theme, colorMode] = useMode();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebar, setIsSidebar] = useState(isMobileOrTablet);
  const [isHideNavBar, setIsHideNavBar] = useState(isMobileOrTablet);

  // Define the margin-left based on the sidebar state
  const contentMarginLeft = isMobileOrTablet ? "0" : isSidebar ? "80" : "270"; // Adjust based on your sidebar width
  const contentWidth = isMobileOrTablet ? "100%" : isSidebar ? "94%" : "82%"; // Adjust based on your sidebar width
  const visionList = process.env.REACT_APP_VISION_LIST
    ? process.env.REACT_APP_VISION_LIST.split(",")
    : [];

  //Menu hide or show status
  const showDashbaord = process.env.REACT_APP_SHOW_DASHBOARD === "true";
  const showVisionModule = process.env.REACT_APP_SHOW_VISION === "true";
  const showImageViewer = process.env.REACT_APP_SHOW_IMAGE_VIEWER === "true";
  const showRejectStation =
    process.env.REACT_APP_SHOW_REJECT_STATION === "true";
  const showUptime = process.env.REACT_APP_SHOW_UPTIME === "true";
  const showReporting = process.env.REACT_APP_SHOW_REPORTING === "true";
  const showTemperature = process.env.REACT_APP_SHOW_TEMPERATURE === "true";
  const showMachineStatus =
    process.env.REACT_APP_SHOW_MACHINE_STATUS === "true";

  //Submenu hide or show status
  const showOutput_FiveMinutes =
    process.env.REACT_APP_SHOW_OUTPUT_FIVEMINUTES === "true";
  const showOutput_Hourly = process.env.REACT_APP_SHOW_OUTPUT_HOURLY === "true";
  const showOutput_Daily = process.env.REACT_APP_SHOW_OUTPUT_DAILY === "true";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <NavigationProvider>
            <CssBaseline />
            <div className="app">
              {!isHideNavBar && (
                <Sidebar
                  setIsSidebar={setIsSidebar}
                  setIsHideNavBar={setIsHideNavBar}
                />
              )}

              <main
                className="content"
                style={{
                  //marginLeft: contentMarginLeft,
                  width: contentWidth,
                  transform: `translateX(${contentMarginLeft}px)`,
                  transition: "transform 0.3s",
                }}
              >
                <Topbar setIsHideNavBar={setIsHideNavBar} />
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/machineStatus" replace />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/resetPassword" element={<ResetPassword />} />
                  <Route path="/profile" element={<UserProfile />} />
                  <Route path="/settings" element={<Settings />}>
                    <Route path="access-rights" element={<Accessibility />} />
                    <Route path="user-account" element={<UserAccount />} />
                  </Route>

                  {showDashbaord && <Route path="/" element={<Dashboard />} />}
                  {showVisionModule &&
                    visionList.map((vision) => (
                      <Route
                        key={vision}
                        path={`/${vision}`}
                        element={<Vision vision={vision} />}
                      />
                    ))}

                  {showUptime && <Route path="/uptime" element={<UpTime />} />}
                  {showOutput_FiveMinutes && (
                    <Route
                      path="/fiveMinutesOutput"
                      element={<FiveMinutesOutput />}
                    />
                  )}
                  {showOutput_Hourly && (
                    <Route path="/hourlyOutput" element={<HourlyOutput />} />
                  )}
                  {showOutput_Daily && (
                    <Route path="/dailyOutput" element={<DailyOutput />} />
                  )}
                  {showImageViewer && (
                    <Route path="/imageviewer" element={<ImageViewer />} />
                  )}
                  {showReporting && (
                    <Route path="/ticketsystem" element={<TicketSystem />} />
                  )}
                  {showTemperature && (
                    <Route
                      path="/machineDetail/:id?"
                      element={<MachineDetail />}
                    />
                  )}
                  {showMachineStatus && (
                    <Route path="/machineStatus" element={<MachineStatus />} />
                  )}
                </Routes>
              </main>
            </div>
            <ToastContainer theme={colorMode === "light" ? "dark" : "light"} />
          </NavigationProvider>
        </ThemeProvider>
      </AuthProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
